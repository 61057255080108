import { Modal } from '@mantine/core';
import { useCallback, useRef } from 'react';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import TransparentButton from '../../../../components/Buttons/TransparentButton';
import { useSelector } from '../../../../redux/store';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';
import { IconX } from '@tabler/icons-react';
import { UpdateDeliveryFormDataType } from '../../../../redux/slices/deliveryFormSlice';
import { isProduction } from '../../../../config/environment';
import QRCode from 'react-qr-code';

type DeliveryAddressModalProps = {
    opened: boolean;
    close: () => void;
    focusKey: string;
    handleDeliveryFormUpdation: (updates: UpdateDeliveryFormDataType) => void;
};
export const DeliveryAddressModal = ({
    opened,
    close,
    focusKey: focusKeyParam,
    handleDeliveryFormUpdation,
}: DeliveryAddressModalProps) => {
    const TranslateFunction = createTranslateFunction(
        'checkout_screen.delivery_form.modals.delivery_address',
    );
    const addresses = useSelector((state) => state.userAuth.user?.addresses);

    const areThereValidAddresses =
        addresses && addresses.length > 0 && !addresses.some((address) => address.city.length <= 2);

    const addAddressQRCode = isProduction
        ? `https://mobile-pizzahut.tlvtech.io/address`
        : `https://mobile-dev-pizzahut.tlvtech.io/address`;

    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        isFocusBoundary: true,
        focusBoundaryDirections: ['down', 'up', 'right', 'left'],
        preferredChildFocusKey: areThereValidAddresses
            ? 'DELIVERY-ADDRESS-0'
            : 'exit-delivery-address-modal-button',
    });
    const modalContainerRef = useRef<HTMLDivElement>(null);
    const onRowFocus = useCallback(
        ({ y }) => {
            if (modalContainerRef.current) {
                modalContainerRef.current.scrollTop = y - 200;
                modalContainerRef.current.style.scrollBehavior = 'smooth';
            }
        },
        [modalContainerRef],
    );

    console.log(addresses, 'These are the addresses!');

    //Pretend its delivery CheckoutDummyData.working_hours.delivery.days.sunday.times[0].start -> .end

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref}>
                <Modal
                    opened={opened}
                    onClose={close}
                    centered={true}
                    size={`40vw`}
                    radius={`3vw`}
                    title={`${TranslateFunction(`modal_title`)}`}
                    withCloseButton={false}
                    overlayProps={{
                        backgroundOpacity: 0.01,
                        blur: 5,
                    }}
                    styles={{
                        header: {
                            backgroundColor: 'black',
                        },
                        content: {
                            backgroundColor: 'black',
                        },
                    }}
                >
                    <div
                        className="bg-black flex flex-col items-center overflow-y-auto min-h-[16vw] max-h-[30vw] p-[2vw]"
                        ref={modalContainerRef}
                    >
                        <TransparentButton
                            className="absolute top-[2vw] right-[2vw] w-[3.3vw] h-[3.3vw] flex items-center justify-center"
                            onClick={() => close()}
                            focusKey={'exit-delivery-address-modal-button'}
                        >
                            <IconX size={'1.7vw'} stroke={2} />
                        </TransparentButton>
                        {areThereValidAddresses ? (
                            <>
                                <span className="text-white text-[2vw] mb-[2vw]">{`${TranslateFunction(`modal_title`)}`}</span>
                                {addresses.map((address, index) => (
                                    <TransparentButton
                                        key={index}
                                        className="mt-[0.9vw] border-black border-2 text-black py-[1vw] text-[2.5vh] w-[32vw] rounded-[1vw]"
                                        focusClassName={`!bg-pizzahut-red !text-white`}
                                        focusKey={`DELIVERY-ADDRESS-${index}`}
                                        onClick={() => {
                                            handleDeliveryFormUpdation({
                                                address: address,
                                                branch_id:
                                                    address.closest_branch.external_branch_id,
                                                delivery_price: parseInt(
                                                    address.closest_branch.properties
                                                        ?.delivery_charge,
                                                ),
                                            });
                                            close();
                                        }}
                                        onBtnFocus={onRowFocus}
                                    >
                                        <div className="flex flex-col">
                                            <span className={`text-[1.6vw] mb-[2vw]`}>
                                                {`${address.street} ${address.no}, ${address.city}`}
                                            </span>
                                            {address.instructions && (
                                                <div className="mr-[3vw] flex flex-col items-start">
                                                    <span className="text-[1.3vw] text-gray-300">{`${TranslateFunction(`delivery_notes`)}`}</span>
                                                    <span className="text-[1.4vw]">
                                                        {address.instructions}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </TransparentButton>
                                ))}
                            </>
                        ) : (
                            <div className="justify-center flex flex-col text-white items-center">
                                <span className="text-[1.8vw] mb-[2vw] text-white">{`${TranslateFunction(`no_addresses_title`)}`}</span>
                                <QRCode
                                    className="w-[12vw] h-[12vw] p-[0.3vw] bg-white border-white"
                                    value={`${addAddressQRCode}`}
                                ></QRCode>
                            </div>
                        )}
                    </div>
                </Modal>
            </div>
            /
        </FocusContext.Provider>
    );
};
