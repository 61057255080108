import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useCallback } from 'react';
import RedButton from '../../components/Buttons/RedButton';
import { useNavigate } from 'react-router-dom';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { useDispatch } from 'react-redux';
import { setAppBackground } from '../../redux/slices/layoutSlice';
import { useSelector } from '../../redux/store';
import PizzahutIcon from '../../assets/pizzahut-icon.svg';

type WelcomeScreenProps = {
    focusKey: string;
};

const WelcomeScreen = ({ focusKey: focusKeyParam }: WelcomeScreenProps) => {
    const TranslateFunction = createTranslateFunction('welcome');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userAuth = useSelector((state) => state.userAuth);

    const { ref, focusKey } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
    });

    // Reset history and set up the screen
    useEffect(() => {
        setFocus(focusKeyParam);
        dispatch(
            setAppBackground([
                'https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/box2.jpg',
                'bg-no-repeat bg-cover bg-opacity-95',
            ]),
        );

        window.history.replaceState({}, '', '/welcome'); // Ensures history is reset

        if (userAuth.user?.auth && userAuth.user.has_completed_onboarding) {
            navigate('/', { replace: true });
        } else {
            localStorage.clear();
        }
    }, [
        focusKeyParam,
        dispatch,
        navigate,
        userAuth.user?.auth,
        userAuth.user?.has_completed_onboarding,
    ]);
    const handleBackButton = useCallback((event: KeyboardEvent) => {
        if (event.keyCode === 461) {
            // webOS Back button
            event.preventDefault(); // Stop default behavior immediately
            event.stopPropagation(); // Prevent bubbling to other listeners

            // Custom logic: Show exit confirmation
            const confirmExit = window.confirm('Do you want to leave the app?');
            if (confirmExit) {
                window.close(); // Exit the app (webOS will handle Home screen if needed)
            }
            // Prevent any further navigation
            return false;
        }
    }, []);

    useEffect(() => {
        // Add listener at the document level with high priority
        document.addEventListener('keydown', handleBackButton, { capture: true });

        return () => {
            document.removeEventListener('keydown', handleBackButton, { capture: true });
        };
    }, [handleBackButton]);

    // Optional: Handle popstate to catch browser history navigation
    useEffect(() => {
        const handlePopState = (event: PopStateEvent) => {
            event.preventDefault();
            // If history navigation occurs, push back to root and show exit prompt
            navigate('/welcome', { replace: true });
            const confirmExit = window.confirm('Do you want to leave the app?');
            if (confirmExit) {
                window.close();
            }
        };

        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
    }, [navigate]);

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="w-full h-full relative" ref={ref}>
                <div className="z-[1] absolute top-[5vw] flex flex-col items-start h-[90%] w-[50vw] mr-[13vw]">
                    <img
                        className="z-[1] h-[8vw] mb-[3vw]"
                        src={PizzahutIcon}
                        alt="Pizzahut Logo"
                    />
                    <span className="z-[1] text-[2.6vw] mb-[1vw] text-white">
                        {TranslateFunction('title')}
                    </span>
                    <span className="z-[1] text-[1.5vw] text-white max-w-[62%] mb-[3vw]">
                        {TranslateFunction('description')}
                    </span>
                    <RedButton
                        className="z-[1] !text-[1.5vw] w-[24vw] h-[5vw] !p-0"
                        focusKey="lets-start-button"
                        onClick={() => navigate('/login')}
                    >
                        {TranslateFunction('lets_start_button')}
                    </RedButton>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default WelcomeScreen;
