import { Modal } from '@mantine/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import TransparentButton from '../../../../components/Buttons/TransparentButton';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';
import { useSelector } from '../../../../redux/store';
import { IconX } from '@tabler/icons-react';
import { UpdateDeliveryFormDataType } from '../../../../redux/slices/deliveryFormSlice';

type OrderTimeModalProps = {
    opened: boolean;
    close: () => void;
    focusKey: string;
    handleDeliveryFormUpdation: (updates: UpdateDeliveryFormDataType) => void;
    isDelivery: boolean;
};
export const OrderTimeModal = ({
    opened,
    close,
    focusKey,
    handleDeliveryFormUpdation,
    isDelivery,
}: OrderTimeModalProps) => {
    const TranslateFunction = createTranslateFunction(
        'checkout_screen.delivery_form.modals.delivery_time',
    );
    const [openHoursArray, setOpenHoursArray] = useState<string[]>([]);
    const branchId = useSelector((state) => state.deliveryFormData.deliveryForm.branch_id);
    const branchesList = useSelector((state) => state.branches.branchesList);
    const { ref } = useFocusable({
        focusKey,
        isFocusBoundary: true,
        focusBoundaryDirections: ['down', 'up', 'right', 'left'],
        preferredChildFocusKey: 'ORDER-TIME-0',
    });
    const modalContainerRef = useRef<HTMLDivElement>(null);
    const onRowFocus = useCallback(
        ({ y }) => {
            if (modalContainerRef.current) {
                modalContainerRef.current.scrollTop = y - 200;
                modalContainerRef.current.style.scrollBehavior = 'smooth';
            }
        },
        [modalContainerRef],
    );

    function parseHourString(hourString: string): Date | null {
        if (hourString === 'עכשיו' || hourString === 'כמה שיותר מהר') {
            return new Date();
        }

        const hourRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        if (!hourRegex.test(hourString)) {
            console.error("Invalid hour format. Please provide the hour in 'HH:mm' format.");
            return null;
        }
        const [hour, minute] = hourString.split(':').map(Number);
        const currentDate = new Date();
        currentDate.setHours(hour, minute, 0, 0);

        return currentDate;
    }

    const getCurrentDayOfWeek = (): string => {
        const daysOfWeek = [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
        ];
        const currentDate = new Date();
        const dayIndex = currentDate.getDay();
        return daysOfWeek[dayIndex];
    };

    const getBranchWorkingHours = (branchData: any): string[] => {
        const currentDay = getCurrentDayOfWeek();
        const workingHours = [
            branchData.working_hours.delivery.days[currentDay].times[0].start,
            branchData.working_hours.delivery.days[currentDay].times[0].end,
        ];
        return workingHours;
    };

    // const generateTimeArray = (startTime: string, endTime: string, interval: number): string[] => {
    //     const result: string[] = [];
    //     let endTimeDate = new Date(`01/01/2022 ${endTime}`);

    //     if (startTime >= endTime) {
    //         endTimeDate = new Date(`01/02/2022 ${endTime}`);
    //     }

    //     const now = new Date();
    //     let futureTime: Date;
    //     if (isDelivery) {
    //         futureTime = new Date(now.getTime() + 40 * 60000);
    //     } else {
    //         futureTime = new Date(now.getTime());
    //     }

    //     // Round up to the next interval
    //     const nowTime = new Date().toLocaleTimeString('en-US', {
    //         hour12: false,
    //         hour: '2-digit',
    //         minute: '2-digit',
    //     });

    //     const roundedFutureTime = new Date(
    //         Math.ceil(futureTime.getTime() / (interval * 60000)) * (interval * 60000),
    //     );

    //     // Get rounded future time in HH:MM format
    //     const roundedFutureTimeString = roundedFutureTime.toLocaleTimeString('en-US', {
    //         hour12: false,
    //         hour: '2-digit',
    //         minute: '2-digit',
    //     });

    //     const nextIntervalStart = new Date(`01/01/2022 ${roundedFutureTimeString}`);

    //     let currentTime = nextIntervalStart;
    //     while (currentTime <= endTimeDate) {
    //         // Get current time in HH:MM format
    //         const currentTimeString = currentTime.toLocaleTimeString('en-US', {
    //             hour12: false,
    //             hour: '2-digit',
    //             minute: '2-digit',
    //         });

    //         // Add current time to the result array
    //         result.push(currentTimeString);

    //         // Increment current time by interval minutes
    //         currentTime = new Date(currentTime.getTime() + interval * 60000);
    //     }
    //     if (isDelivery) {
    //         return [
    //             TranslateFunction('as_soon_as_possible'),
    //             roundedFutureTimeString,
    //             ...result.slice(1),
    //         ];
    //     }
    //     return [TranslateFunction('first_time'), ...result.slice(1)];
    // };

    const generateTimeArray = (startTime: string, endTime: string, interval: number): string[] => {
        const result: string[] = [];
        let endTimeDate = new Date(`01/01/2022 ${endTime}`);

        if (startTime >= endTime) {
            endTimeDate = new Date(`01/02/2022 ${endTime}`);
        }

        const now = new Date();

        // Round up to the next interval
        const roundedFutureTime = new Date(
            Math.ceil(now.getTime() / (interval * 60000)) * (interval * 60000),
        );

        // Get rounded future time in HH:MM format
        const roundedFutureTimeString = roundedFutureTime.toLocaleTimeString('en-US', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
        });

        const nextIntervalStart = new Date(`01/01/2022 ${roundedFutureTimeString}`);

        let currentTime = nextIntervalStart;
        while (currentTime <= endTimeDate) {
            const currentTimeString = currentTime.toLocaleTimeString('en-US', {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit',
            });

            result.push(currentTimeString);

            currentTime = new Date(currentTime.getTime() + interval * 60000);
        }

        return [
            isDelivery ? TranslateFunction('as_soon_as_possible') : TranslateFunction('first_time'),
            ...result.slice(1),
        ];
    };

    const handleTimeGenerationFunction = () => {
        if (!branchId) {
            return;
        }
        const currentBranchData = branchesList.find(
            (branch) => branch.external_branch_id === branchId,
        );
        if (!currentBranchData) {
            return;
        }
        const workingHours = getBranchWorkingHours(currentBranchData.atmos_data);
        setOpenHoursArray(generateTimeArray(workingHours[0], workingHours[1], 15));
    };

    useEffect(() => {
        handleTimeGenerationFunction();
    }, [branchId]);

    return (
        <FocusContext.Provider value="ORDER-TIME-MODAL">
            <div ref={ref}>
                <Modal
                    opened={opened}
                    onClose={close}
                    centered={true}
                    size={`40vw`}
                    radius={`3vw`}
                    withCloseButton={false}
                    overlayProps={{
                        backgroundOpacity: 0.01,
                        blur: 5,
                    }}
                    styles={{
                        header: {
                            backgroundColor: 'black',
                        },
                        content: {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    <div
                        className="bg-black flex flex-col items-center overflow-y-auto max-h-[30vw] p-[2vw]"
                        ref={modalContainerRef}
                    >
                        <TransparentButton
                            className="absolute top-[1vw] right-[2vw] w-[3.3vw] h-[3.3vw] flex items-center justify-center"
                            onClick={() => close()}
                        >
                            <IconX size={'1.7vw'} stroke={2} />
                        </TransparentButton>
                        <span className="text-white text-[2vw] mb-[1vw]">{`${isDelivery ? TranslateFunction('modal_title_delivery') : TranslateFunction('modal_title_pickup')}`}</span>
                        {/* <div className="w-[100%] h-[100%] absolute overflow-hidden bg-gradient-to-b from-transparent from-40% to-black to-100% z-[2]"></div> */}
                        {openHoursArray.map((hour, index) => (
                            <TransparentButton
                                key={index}
                                className="mt-[0.6vw] border-black border-2 text-black !py-[1vw] !text-[1.7vw] w-[30vw] h-[10vw] rounded-[3vw]"
                                focusClassName={`!bg-pizzahut-red !text-white`}
                                focusKey={`ORDER-TIME-${index}`}
                                onClick={() => {
                                    handleDeliveryFormUpdation({
                                        future_date: parseHourString(hour) || undefined,
                                    });
                                    close();
                                }}
                                onBtnFocus={onRowFocus}
                            >
                                {hour}
                            </TransparentButton>
                        ))}
                    </div>
                </Modal>
            </div>
            /
        </FocusContext.Provider>
    );
};
