import { getAccessToken } from '../../lib/auth';
import api from '../../lib/axios';

export const trackOrderFunction = async (phone) => {
    try {
        const response = await api.post(
            '/user/track-order',
            {
                phone: phone,
            },
            {
                headers: {
                    Authorization: `Bearer ${getAccessToken()?.access_token}`,
                },
            },
        );

        if (response.data.success) {
            console.log('Request successful:', response.data.data);
        } else {
            console.log('Request failed:', response.data.error);
        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
};
/**
 * Calculate the remaining minutes for a given received date.
 * @param {string} receivedDate - The date and time that the order was received in ISO format.
 * @returns {number} The remaining minutes, capped at 0 if the order is older than 50 minutes.
 */
export function getRemainingMinutes(receivedDate: string): number {
    const receivedTime = new Date(receivedDate);
    const currentTime = new Date();

    const elapsedMilliseconds = currentTime.getTime() - receivedTime.getTime();
    const elapsedMinutes = Math.floor(elapsedMilliseconds / (1000 * 60));

    const remainingMinutes = Math.max(50 - elapsedMinutes, 0);
    return remainingMinutes;
}
