import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Address, User } from '../../types/user';
import { OrderModel, OrderType, PaymentMethodType } from './ordersSlice';
import api from '../../lib/axios';
import { CartItem } from './cartSlice';
import { getAccessToken } from '../../lib/auth';
import { PaymentMethod } from '../../types/paymentMethod';

export type deliveryFormDataType = {
    _id?: string;
    address?: Address;
    branch_id?: number;
    client_name?: string;
    phone_number?: string;
    cart?: CartItem[];
    future_date?: Date;
    notes?: string;
    delivery_price: number;
    order_type: OrderType;
    payment_method: PaymentMethodType;
    last_order_id?: string;
    payment_card?: PaymentMethod;
    payment_link?: string;
};

type InitialStateType = {
    deliveryForm: deliveryFormDataType;
    isOrderSubmitted: boolean;
    orderSubmissionError: string | null;
    lastOrder: OrderModel;
};

const userString = localStorage.getItem('user');
const currentUser: User | null = userString ? JSON.parse(userString) : null;
const localStorageCart = localStorage.getItem('cart');
const initialState: InitialStateType = {
    deliveryForm: {
        client_name: `${currentUser?.first_name} ${currentUser?.last_name}` || ``,
        phone_number: localStorage.getItem('phoneNumber') || ``,
        cart: localStorageCart ? JSON.parse(localStorageCart) : [],
        notes: '',
        delivery_price: 0,
        order_type: 0,
        payment_method: 0,
    },
    isOrderSubmitted: false,
    orderSubmissionError: null,
    lastOrder: {
        _id: localStorage.getItem('LastOrderId') || undefined,
    },
};

export type UpdateDeliveryFormDataType = Partial<deliveryFormDataType>;

export const createNewOrder = createAsyncThunk<OrderModel, OrderModel>(
    'deliveryForm/createNewOrder',
    async (DeliveryForm) => {
        console.log('CREATING NEW ORDER');
        const client_name = `${currentUser?.first_name} ${currentUser?.last_name}` || `WebOS-Guest`;
        const phone_number = (await localStorage.getItem('phoneNumber')) || '';

        const { data } = await api.post(
            'order',
            {
                ...DeliveryForm,

                //Update the client name from the local storage.
                client_name: client_name,
                phone_number: phone_number,
                cart: JSON.parse(localStorage.getItem('cart') || ''),
            },
            {
                headers: {
                    Authorization: `Bearer ${getAccessToken()?.access_token}`,
                },
            },
        );
        return data.order;
    },
);

const deliveryFormSlice = createSlice({
    name: 'deliveryForm',
    initialState,
    reducers: {
        UpdateDeliveryFormData: (
            state,
            action: PayloadAction<Partial<UpdateDeliveryFormDataType>>,
        ) => {
            const deliveryFormDataUpdates = action.payload;
            state.deliveryForm = { ...state.deliveryForm, ...deliveryFormDataUpdates };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createNewOrder.rejected, (state, action) => {
            console.log('ERROR!, action:', action);
            if (action.error.message) {
                state.orderSubmissionError = action.error.message;
            }
        });
        builder.addCase(createNewOrder.fulfilled, (state, action) => {
            console.log('fulfilled, action:', action);
            state.lastOrder = action.payload;
            state.isOrderSubmitted = true;
        });
    },
});

// Export actions
export const { UpdateDeliveryFormData } = deliveryFormSlice.actions;

// Export reducer
export default deliveryFormSlice.reducer;
