import { Route, Routes, useNavigate } from 'react-router-dom';
import { Sidebar } from './layouts/Sidebar/Sidebar';
import { HomeScreen } from './screens/HomeScreen/HomeScreen';
import { init } from '@noriginmedia/norigin-spatial-navigation';
import { SearchScreen } from './screens/SearchScreen/SearchScreen';
import { BranchesScreen } from './screens/BranchesScreen/BranchesScreen';
import { ProfileScreen } from './screens/ProfileScreen/ProfileScreen';
import { SettingsScreen } from './screens/SettingsScreen/SettingsScreen';
import { ChosenMenuScreen } from './screens/Menu/ChosenMenuScren/ChosenMenuScreen';
import { ChosenBranchScreen } from './screens/BranchesScreen/ChosenBranchScreen';
import LoginScreen from './screens/AuthScreens/LoginScreen';
import RegisterScreen from './screens/AuthScreens/RegisterScreen';
import LoginVerifyScreen from './screens/AuthScreens/LoginVerifyScreen';
import WelcomeScreen from './screens/AuthScreens/WelcomeScreen';
import { useEffect, useState } from 'react';
import CheckoutScreen from './screens/CheckoutScreen.tsx/CheckoutScreen';
import { EditFoodScreen } from './screens/EditFoodScreen/EditFoodScreen';
import { CouponCodePopup } from './screens/CouponCodeScreen/CouponCodePopup';
import { useSelector } from './redux/store';
import CartPopup from './components/Cart/CartPopup';
import './i18n/config';
import FavoriteItemsScreen from './screens/FavoriteItemsScreen/FavoriteItemsScreen';
import RestorePastOrderScreen from './screens/HomeScreen/PastOrders/RestorePastOrderScreen/RestorePastOrderScreen';
import ChoosePaymentScreen from './screens/PaymentScreen/ChoosePaymentScreen';
import TrackOrderScreen from './screens/TrackOrderScreen/TrackOrderScreen';
import { PersonalAssistantScreen } from './screens/PersonalAssistantScreen/PersonalAssistantScreen';
import { Bounce, ToastContainer } from 'react-toastify';

init({
    debug: false,
    visualDebug: false,
});

export type IAsset = {
    title: string;
    img: string;
};

const iconUrlBase = 'https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/sidebar/';

const sidebarMenuItems = [
    { label: 'חיפוש', icon: `${iconUrlBase}sidebar_search_icon.svg` },
    { label: 'מסך הבית', icon: `${iconUrlBase}sidebar_home_icon.svg` },
    { label: 'תפריט', icon: `${iconUrlBase}sidebar_menu_icon.svg` },
    { label: 'סניפים', icon: `${iconUrlBase}sidebar_branches_icon.svg` },
    { label: 'הגדרות', icon: `${iconUrlBase}sidebar_settings_icon.svg` },
    { label: 'אהבתי', icon: `${iconUrlBase}sidebar_loveditems_icon.svg` },
];

function AppContainer({ children }: { children: React.ReactNode }) {
    const resolutionTailwindClass = 'w-full h-full';
    const { appBackgroundTwClass, appBackgroundSrc } = useSelector((state) => state.layout);
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    useEffect(() => {
        setIsImageLoaded(false);
        if (appBackgroundSrc) {
            const img = new Image();
            img.src = appBackgroundSrc;
            img.onload = () => setIsImageLoaded(true);
        }
    }, [appBackgroundSrc]);

    return (
        <div
            style={{ backgroundImage: `url(${appBackgroundSrc})` }}
            className={`
                text-white flex flex-row 
                ${resolutionTailwindClass}
                ${appBackgroundTwClass ? appBackgroundTwClass : 'bg-black'}
            `}
        >
            {!isImageLoaded && <div className="absolute inset-0 bg-black"></div>}
            <div className="absolute inset-0 bg-black bg-opacity-30"></div>
            {children}
        </div>
    );
}

function AuthRoutes() {
    return (
        <Routes>
            <Route path="/welcome" element={<WelcomeScreen focusKey="WelcomeScreen" />} />
            <Route path="/register" element={<RegisterScreen focusKey="RegisterScreen" />} />
            <Route path="/login" element={<LoginScreen focusKey="LoginScreen" />} />
            <Route
                path="/loginverify"
                element={<LoginVerifyScreen focusKey="LoginScreenVerify" />}
            />
            <Route path="*" element={<WelcomeScreen focusKey="WelcomeScreen" />} />
        </Routes>
    );
}

function ProtectedRoutes() {
    const navigate = useNavigate();
    const { user, token } = useSelector((state) => state.userAuth);
    const { isCouponCodeOpen } = useSelector((state) => state.couponCode);
    const { isCartOpen } = useSelector((state) => state.cart);

    useEffect(() => {
        if (!user || !token) {
            navigate('/welcome', { replace: true });
        }
    }, [user, token, navigate]);

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={4000}
                closeOnClick={false}
                closeButton={false}
                toastStyle={{
                    width: '40vw',
                    height: '5vw',
                    fontSize: '1.8vw',
                    textAlign: 'center',
                    borderRadius: '2vw',
                    position: 'absolute',
                    top: '2vw',
                    right: '-15vw',
                }}
                pauseOnFocusLoss={false}
                rtl={false}
                draggable={false}
                pauseOnHover={false}
                theme="colored"
                transition={Bounce}
            />
            <Sidebar focusKey="SIDEBAR" menuItems={sidebarMenuItems} />
            {isCouponCodeOpen && <CouponCodePopup focusKey="COUPONCODEPOPUP" />}
            {isCartOpen && <CartPopup focusKey="CARTPOPUP" />}
            <div className="flex-1 overflow-hidden flex flex-col relative">
                <Routes>
                    <Route path="/" element={<HomeScreen focusKey="HOME" />} />
                    <Route
                        path="/menu/:chosenMenuScreen"
                        element={<ChosenMenuScreen focusKey="CHOSENMENU" />}
                    />
                    <Route path="/search" element={<SearchScreen focusKey="SEARCH" />} />
                    <Route
                        path="/personal-assistant"
                        element={<PersonalAssistantScreen focusKey="PERSONAL-ASSISTANT-SCREEN" />}
                    />
                    <Route
                        path="/checkout"
                        element={<CheckoutScreen focusKey="CHECKOUTSCREEN" />}
                    />
                    <Route path="/branches" element={<BranchesScreen focusKey="BRANCHES" />} />
                    <Route
                        path="/chosen-branch"
                        element={<ChosenBranchScreen focusKey="CHOSEN-BRANCH-SCREEN" />}
                    />
                    <Route
                        path="/editfoodscreen"
                        element={<EditFoodScreen focusKey="EDITFOODSCREEN" />}
                    />
                    <Route path="/profile" element={<ProfileScreen focusKey="PROFILE" />} />
                    <Route path="/settings" element={<SettingsScreen focusKey={'SETTINGS'} />} />
                    <Route
                        path="/favorite-items"
                        element={<FavoriteItemsScreen focusKey="FAVORITE-ITEMS" />}
                    />
                    <Route
                        path="/restore-past-order"
                        element={<RestorePastOrderScreen focusKey="RESTORE-PAST-ORDER" />}
                    />
                    <Route
                        path="/choose-payment"
                        element={<ChoosePaymentScreen focusKey="CHOOSE-PAYMENT-SCREEN" />}
                    />
                    <Route
                        path="/track-order/:orderId"
                        element={<TrackOrderScreen focusKey="TRACK-ORDER-SCREEN" />}
                    />
                    <Route path="*" element={<HomeScreen focusKey="HOME" />} />
                </Routes>
            </div>
        </>
    );
}

function App() {
    const { user, token } = useSelector((state) => state.userAuth);

    return <AppContainer>{!user || !token ? <AuthRoutes /> : <ProtectedRoutes />}</AppContainer>;
}

export default App;
