import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../lib/axios';
import { AxiosResponse } from 'axios';
import { Address, User } from '../../types/user';
import { getAccessToken } from '../../lib/auth';
import { CartItem } from './cartSlice';

export interface AtmosCartItem {
    amount?: number;
    price: number;
    total_price: number;
    tax?: number;
    makat: number;
    name: string;
    children?: AtmosCartItem[];
    extras: AtmosExtra[];
}

export interface AtmosExtra {
    _id: string;
    extraSize: string;
    name: string;
    makat: number;
    price: number;
    amount?: number;
}
export type ItemModel = {
    _id: string;
    created_at?: number;
    updated_at?: number;
    makat: number;
    name: string;
    price: number;
    extras: ExtraModel[];
    name_en: string;
    category: string;
    main_category: string;
};
export type ExtraModel = {
    _id: string;
    created_at?: number;
    updated_at?: number;
    makat: number;
    name: string;
    price: number;
    extraSize: string;
};

export type SauceModel = {
    _id: string;
    created_at?: number;
    updated_at?: number;
    makat: number;
    name: string;
    price: number;
    sauceQuantity: number;
};

export type OrderModel = {
    _id?: string;
    user?: string;
    branch_id?: number;
    address?: Address;
    client_name?: string;
    phone_number?: string;
    items?: AtmosCartItem[];
    cart?: CartItem[];
    future_date?: Date;
    notes?: string;
    total_price?: number;
    delivery_price?: number;
    order_type?: OrderType;
    payment_method?: PaymentMethodType;
    status?: 'pending' | 'complete';
    created_at?: number;
    updated_at?: number;
};

export enum PaymentMethodType {
    CARD = 0,
    CASH,
    GOOGLE_PAY,
    APPLE_PAY,
}

export enum OrderType {
    DELIVERY = 0,
    PICKUP,
}

export enum OrderStatusType {
    STATUS_OPEN = 0,
    STATUS_PREPARATION = 1,
    STATUS_READY = 2,
    STATUS_ON_DELIVERY = 3,
    STATUS_CLOSED = 4,
    STATUS_CANCELED = 5,
}

export const ObjectOrderStatusType = {
    0: 'STATUS_OPEN',
    1: 'STATUS_PREPARATION',
    2: 'STATUS_READY',
    3: 'STATUS_ON_DELIVERY',
    4: 'STATUS_CLOSED',
    5: 'STATUS_CANCELED',
} as const;

type OrderStatusConvertedType =
    | 'pending'
    | 'in_progress'
    | 'ready_for_delivery'
    | 'on_delivery'
    | 'done';

export const OrderStatusObject: Record<number, OrderStatusConvertedType> = {
    0: 'pending',
    1: 'in_progress',
    2: 'ready_for_delivery',
    3: 'on_delivery',
    4: 'done',
};

export type AtmosOrderModel = {
    _id?: string;
    user: User;
    branch_id: number;
    address: Address;
    client_name?: string;
    phone_number?: string;
    items: AtmosCartItem[];
    future_date?: Date;
    notes: string;
    total_price: number;
    delivery_price: number;
    order_type: OrderType;
    payment_method: PaymentMethodType;
    status: 'pending' | 'complete';
    created_at?: number;
    updated_at?: number;
    isRestored?: boolean;
};

export type Order = {
    _id: string;
    created_at: number;
    updated_at: number;
    cart: CartItem[];
    address?: Address;
    user: User;
    isRestored?: boolean;
};

interface LastOrderAtmosResponse {
    id: string;
    date_registered: string;
    first_order_date: string;
    last_order_date: string;
    name: string;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    birth: string;
    avatar: string;
    wedding: string;
    last_order: any[]; // Adjust the type if you have a specific structure for last_order items
}

type OrdersState = {
    loading: boolean;
    orders: Order[];
    error: string | null;
    last_order?: LastOrderAtmosResponse | null;
    last_order_status: OrderStatusType;
    last_order_converted_status: OrderStatusConvertedType;
};
export type UserOrderProps = {
    created_at: Date;
    description: string;
    updated_at: string;
    user: string;
    _id: string;
};

const initialState: OrdersState = {
    loading: false,
    orders: [],
    error: null,
    last_order: null,
    last_order_status: 0,
    last_order_converted_status: OrderStatusObject[0],
};

export const fetchPastOrders = createAsyncThunk<AxiosResponse>(
    'orders/fetchPastOrders',
    async () => {
        const accessToken = getAccessToken()?.access_token;

        if (!accessToken) {
            throw new Error('Access token is not valid!');
        }
        const fetchMeResponse = await api.get('/order/my-orders', {
            headers: {
                Authorization: `Bearer ${getAccessToken()?.access_token}`,
            },
        });
        return fetchMeResponse;
    },
);

export const trackLastOrder = createAsyncThunk<AxiosResponse>('orders/trackLastOrder', async () => {
    let phoneNumber = localStorage.getItem('phoneNumber');
    if (!phoneNumber) {
        return;
    }
    phoneNumber = phoneNumber.replace(/^\+972/, '0');
    const response = await api.post(
        '/user/track-order',
        {
            phone: phoneNumber,
        },
        {
            headers: {
                Authorization: `Bearer ${getAccessToken()?.access_token}`,
            },
        },
    );
    return response.data;
});

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        changeIsRestored: (state, action) => {
            const { id, isRestored } = action.payload;
            if (state.orders.find((order) => order._id === id)) {
                const orderIndex = state.orders.findIndex((order) => order._id === id);
                state.orders[orderIndex].isRestored = isRestored;
            }
        },
        setLastOrderStatus: (state, action) => {
            const newOrderStatus = action.payload;
            state.last_order_status = newOrderStatus;
            state.last_order_converted_status = OrderStatusObject[newOrderStatus];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPastOrders.pending, (state) => {
                console.log('pending');
                state.loading = true;
            })
            .addCase(fetchPastOrders.fulfilled, (state, action) => {
                console.log('fulfilled, action:', action);
                state.loading = false;
                state.orders = action.payload.data;
                state.error = null;
            })
            .addCase(fetchPastOrders.rejected, (state, action) => {
                console.log('rejected, action:', action);
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch orders';
            })
            .addCase(trackLastOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(trackLastOrder.fulfilled, (state, action) => {
                // console.log('fulfilled, action:', action);
                state.loading = false;
                state.last_order = action.payload.data;
                const incomingStatus = action.payload.data.last_order[0].status || 0;
                state.last_order_status = incomingStatus;
                state.last_order_converted_status = OrderStatusObject[incomingStatus];
                state.error = null;
            })
            .addCase(trackLastOrder.rejected, (state, action) => {
                // console.log('rejected, action:', action);
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch orders';
            });
    },
});
export const { changeIsRestored, setLastOrderStatus } = ordersSlice.actions;
export default ordersSlice.reducer;
