import React from 'react';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useNavigate } from 'react-router-dom';
import TransparentButton from '../../components/Buttons/TransparentButton';

export const ProfileScreen = ({ focusKey: focusKeyParam }) => {
    const navigate = useNavigate();
    const { ref, focusKey } = useFocusable({
        focusable: true,
        trackChildren: true,
        focusKey: focusKeyParam,
    });
    return (
        <FocusContext.Provider value={focusKey}>
            <div className="h-full w-full p-[3vw] flex flex-col" ref={ref}>
                <TransparentButton
                    className="text-[2vw] w-[16vw] h-[6vw]"
                    onClick={() => navigate('/', { replace: true })}
                >
                    {`לעמוד הראשי`}
                </TransparentButton>
                <span className="text-[3vw]">{`עדיין בבנייה :-)`}</span>
            </div>
        </FocusContext.Provider>
    );
};
