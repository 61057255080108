import createTranslateFunction from '../../i18n/createTranslateFunction';
import StatusContainer from './StatusContainer';

const TrackingStatusBar = () => {
    const StatusContainerTranslation = createTranslateFunction('track_order.statuses');
    const statusContainerOptions = [0, 1, 2, 3, 4];
    return (
        <div className="w-[50vw] relative flex flex-col pt-[3vw]">
            <span className="text-[1.8vw]">{StatusContainerTranslation('order_status')}</span>
            <div className="z-[1] absolute top-[36vw] right-[3.5vw] w-[0.3vw] h-[5.9vw] text-[3vw] rotate-90 text-white text-opacity-40">{`........................................`}</div>
            {statusContainerOptions.map((index) => (
                <StatusContainer key={`status-container-${index}`} containerStatusValue={index} />
            ))}
        </div>
    );
};

export default TrackingStatusBar;
